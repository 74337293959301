import React from 'react';

import {Icon} from '@chakra-ui/react';
import {
    MdBarChart,
    MdPerson,
    MdHome,
    MdLock,
    MdOutlineShoppingCart,
    MdHourglassEmpty,
    MdProductionQuantityLimits
} from 'react-icons/md';

import MainDashboard from 'views/admin/default';
import Orders from 'views/admin/orders';
import PartialOrders from 'views/admin/partials';
import Products from 'views/admin/products';
import Roas from 'views/admin/roas';
import Form from 'views/admin/forms';
import ProductForm from 'views/admin/products/ProductForm';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

import SignInCentered from 'views/auth/signIn';
import Settings from "./views/admin/settings";
import ChangePassword from "./views/admin/changePassword";

const routes = [
    {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/default',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit"/>,
        component: <MainDashboard/>,
    },
    {
        name: 'Orders',
        layout: '/admin',
        path: '/orders',
        icon: (
            <Icon
                as={MdOutlineShoppingCart}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: <Orders />,
    },
    {
        name: 'Partial Orders',
        layout: '/admin',
        path: '/partial-orders',
        icon: (
            <Icon
                as={MdHourglassEmpty}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: <PartialOrders />,
    },
    {
        name: 'Products',
        layout: '/admin',
        path: '/products',
        icon: (
            <Icon
                as={MdProductionQuantityLimits}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: <Products />,
    },
    {
        dontShow: true,
        name: 'Edit Form',
        layout: '/admin',
        path: '/forms/:formId/:productId',
        component: <Form />,
    },
    {
        dontShow: true,
        name: 'Create Form',
        layout: '/admin',
        path: '/products/:productId/create-form',
        component: <Form />,
    },
    {
        dontShow: true,
        name: 'Edit Product',
        layout: '/admin',
        path: '/edit-product/:id',
        component: <ProductForm />,
    },
    {
        dontShow: true,
        name: 'Create Product',
        layout: '/admin',
        path: '/create-product',
        component: <ProductForm />,
    },
    {
        dontShow: true,
        name: 'Duplicate Product',
        layout: '/admin',
        path: '/duplicate-product/:id',
        component: <ProductForm />,
    },
    {
        dontShow: true,
        name: 'Data Tables',
        layout: '/admin',
        icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit"/>,
        path: '/data-tables',
        component: <DataTables/>,
    },
    {
        name: 'ROAS',
        layout: '/admin',
        path: '/roas',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <Roas/>,
    },
    {
        dontShow: true,
        name: 'Settings',
        layout: '/admin',
        path: '/settings',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <Settings/>,
    },
    {
        dontShow: true,
        name: 'Change Password',
        layout: '/admin',
        path: '/change-password',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <ChangePassword/>,
    },
    {
        dontShow: true,
        name: 'Profile',
        layout: '/admin',
        path: '/profile',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit"/>,
        component: <Profile/>,
    },
    {
        dontShow: true,
        name: 'Sign In',
        layout: '/auth',
        path: '/sign-in',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <SignInCentered/>,
    },
    {
        dontShow: true,
        name: 'RTL Admin',
        layout: '/rtl',
        path: '/rtl-default',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit"/>,
        component: <RTL/>,
    },
];

export default routes;
