import React, { useState, useEffect } from 'react'
import axiosInstance from "../../../api/axiosInstance"
import {
    Container,
    Box,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    InputGroup,
    InputRightElement,
    Tooltip,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Stack,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel
} from '@chakra-ui/react'
import { FiEye, FiPlus, FiMinus } from 'react-icons/fi'
import { useNotification } from "../../../contexts/NotificationContext"

const SettingsPage = () => {
    const [settings, setSettings] = useState({
        woocommerce_store_url: '',
        woocommerce_consumer_key: '',
        woocommerce_consumer_secret: '',
        facebook_pixel_script: '',
        thank_you_page: '',
        conversion_api_token: '',
        tiktok_pixel_script: ''
    })

    const [apiKey, setApiKey] = useState('')
    const [showStars, setShowStars] = useState(true)
    const [syncing, setSyncing] = useState(false)
    const [emailRecipients, setEmailRecipients] = useState([''])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { addNotification, addErrorNotifications } = useNotification()

    useEffect(() => {
        fetchSettings()
    }, [])

    const fetchSettings = async () => {
        try {
            const response = await axiosInstance.get('/settings', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            setSettings(response.data)
        } catch (error) {
            console.error('Error fetching settings', error)
        }
    }

    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await axiosInstance.post('/settings', settings, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            addNotification('Settings saved successfully', 'success')
        } catch (error) {
            addErrorNotifications('Failed to save settings')
        }
    }

    const handleShowApiKey = async () => {
        try {
            const response = await axiosInstance.get('/settings/api-key', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            setApiKey(response.data.api_key)
            setShowStars(false)
        } catch (error) {
            console.error('Error fetching API key', error)
        }
    }

    const handleSyncOrders = async () => {
        setSyncing(true)
        try {
            await axiosInstance.post('/sync-woocommerce-orders', {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            addNotification('Orders started to synchronize. You can leave this page', 'success')
        } catch (error) {
            addErrorNotifications('Failed to sync orders')
        } finally {
            setSyncing(false)
        }
    }

    const handleAddRecipient = () => {
        if (emailRecipients.length >= 2) {
            addNotification('Maximum of email recipients is 2', 'error')
            return
        }
        setEmailRecipients([...emailRecipients, ''])
    }

    const handleRemoveRecipient = (index) => {
        const updatedRecipients = [...emailRecipients]
        updatedRecipients.splice(index, 1)
        setEmailRecipients(updatedRecipients)
    }

    const handleRecipientChange = (index, event) => {
        const updatedRecipients = [...emailRecipients]
        updatedRecipients[index] = event.target.value
        setEmailRecipients(updatedRecipients)
    }

    const openModal = async () => {
        try {
            const response = await axiosInstance.get('/email-recipients', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            if (response.data.length) {
                setEmailRecipients(response.data.map(emailObj => emailObj.email))
            }
            setIsModalOpen(true)
        } catch (error) {
            console.error('Error fetching email recipients', error)
        }
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleSaveRecipients = async () => {
        try {
            await axiosInstance.post('/email-recipients', { emailRecipients }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
            addNotification('Email recipients saved successfully', 'success')
            closeModal()
        } catch (error) {
            addErrorNotifications('Failed to save email recipients')
        }
    }

    return (
        <Container maxW="container.xl" py={8} mt={20}>
            <Box bg="white" shadow="md" borderRadius="md" p={6}>

                <form onSubmit={handleSubmit}>
                    <Tabs variant="line" colorScheme="blue">
                        <TabList>
                            <Tab>General</Tab>
                            <Tab>Facebook</Tab>
                            <Tab>TikTok</Tab>
                        </TabList>

                        <TabPanels>
                            {/* GENERAL TAB */}
                            <TabPanel>
                                <Stack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>WooCommerce Store URL</FormLabel>
                                        <Input
                                            name="woocommerce_store_url"
                                            value={settings.woocommerce_store_url}
                                            onChange={handleChange}
                                            placeholder="Enter store URL"
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Thank you page (OPTIONAL)</FormLabel>
                                        <Input
                                            name="thank_you_page"
                                            value={settings.thank_you_page}
                                            onChange={handleChange}
                                            placeholder={`EX: ${settings.woocommerce_store_url ? settings.woocommerce_store_url : 'https://domain.ro/thank-you'}/thank-you`}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>WooCommerce Consumer Key</FormLabel>
                                        <Input
                                            name="woocommerce_consumer_key"
                                            value={settings.woocommerce_consumer_key}
                                            onChange={handleChange}
                                            placeholder="Enter consumer key"
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>WooCommerce Consumer Secret</FormLabel>
                                        <Input
                                            name="woocommerce_consumer_secret"
                                            value={settings.woocommerce_consumer_secret}
                                            onChange={handleChange}
                                            placeholder="Enter consumer secret"
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>API Key</FormLabel>
                                        <InputGroup>
                                            <Input value={showStars ? '**********' : apiKey} isReadOnly />
                                            <InputRightElement>
                                                <Tooltip label="Show the API key" hasArrow>
                                                    <IconButton
                                                        aria-label="Show API key"
                                                        icon={<FiEye />}
                                                        onClick={handleShowApiKey}
                                                        variant="ghost"
                                                        size="sm"
                                                    />
                                                </Tooltip>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>

                                    <Button
                                        onClick={openModal}
                                        colorScheme="yellow"
                                        mt={6}
                                        width="100%"
                                    >
                                        Add Email Recipients
                                    </Button>
                                </Stack>
                            </TabPanel>

                            {/* FACEBOOK TAB */}
                            <TabPanel>
                                <Stack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>Facebook Pixel ID (OPTIONAL)</FormLabel>
                                        <Textarea
                                            name="facebook_pixel_script"
                                            value={settings.facebook_pixel_script}
                                            onChange={handleChange}
                                            placeholder="Enter Facebook Pixel script"
                                            rows={4}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Conversion API Token (OPTIONAL)</FormLabel>
                                        <Input
                                            name="conversion_api_token"
                                            value={settings.conversion_api_token}
                                            onChange={handleChange}
                                            placeholder="Enter conversion API token"
                                        />
                                    </FormControl>
                                </Stack>
                            </TabPanel>

                            {/* TIKTOK TAB */}
                            <TabPanel>
                                <Stack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>TikTok Pixel (OPTIONAL)</FormLabel>
                                        <Input
                                            name="tiktok_pixel_script"
                                            value={settings.tiktok_pixel_script}
                                            onChange={handleChange}
                                            placeholder="Enter TikTok pixel"
                                        />
                                    </FormControl>
                                </Stack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Button type="submit" colorScheme="green" mt={6} width="100%">
                        Save Settings
                    </Button>
                    {/* Uncomment if you want the sync functionality visible
          <Button
            onClick={handleSyncOrders}
            colorScheme="teal"
            mt={4}
            isLoading={syncing}
            loadingText="Syncing"
          >
            Sync Orders from WooCommerce
          </Button>
          */}
                </form>
            </Box>

            {/* Modal for adding email recipients */}
            <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Email Recipients</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            {emailRecipients.map((email, index) => (
                                <FormControl key={index}>
                                    <FormLabel>Email</FormLabel>
                                    <InputGroup>
                                        <Input
                                            value={email}
                                            onChange={(e) => handleRecipientChange(index, e)}
                                            placeholder="Enter email"
                                        />
                                        <InputRightElement width="4.5rem">
                                            <IconButton
                                                aria-label="Add recipient"
                                                icon={<FiPlus />}
                                                onClick={handleAddRecipient}
                                                variant="ghost"
                                                size="sm"
                                            />
                                            {emailRecipients.length > 1 && (
                                                <IconButton
                                                    aria-label="Remove recipient"
                                                    icon={<FiMinus />}
                                                    onClick={() => handleRemoveRecipient(index)}
                                                    variant="ghost"
                                                    size="sm"
                                                    ml={2}
                                                />
                                            )}
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            ))}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSaveRecipients}>
                            Save Recipients
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default SettingsPage