import React, { useState } from 'react'
import axiosInstance from "../../../api/axiosInstance";
import {
    Container,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    Heading,
    Text,
    IconButton,
    VStack,
} from '@chakra-ui/react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import {useNotification} from "../../../contexts/NotificationContext";

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const { addNotification, addErrorNotifications } = useNotification()
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const navigate = useNavigate()

    const handleClickShowPassword = (type) => {
        switch (type) {
            case 'current':
                setShowCurrentPassword(!showCurrentPassword)
                break
            case 'new':
                setShowNewPassword(!showNewPassword)
                break
            case 'confirm':
                setShowConfirmPassword(!showConfirmPassword)
                break
            default:
                break
        }
    }

    const handleMouseDownPassword = (e) => {
        e.preventDefault()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match')
            return
        }
        try {
            const response = await axiosInstance.post('/change-password', {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmPassword,
            })
            setMessage(response.data.message)
            addNotification("Password changed successfully!", "success")
            navigate('/admin/default')
        } catch (error) {
            setMessage(error.response?.data?.error || 'An error occurred')
        }
    }

    return (
        <Container maxW="sm" py={8} mt={20}>
            <Box bg="white" shadow="md" borderRadius="md" p={6}>
                <Heading as="h1" size="lg" mb={6} textAlign="center">
                    Change Password
                </Heading>
                <Box as="form" onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl id="current-password" isRequired>
                            <FormLabel>Current Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    placeholder="Enter current password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label="Toggle current password visibility"
                                        icon={showCurrentPassword ? <FiEyeOff /> : <FiEye />}
                                        onClick={() => handleClickShowPassword('current')}
                                        onMouseDown={handleMouseDownPassword}
                                        size="sm"
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="new-password" isRequired>
                            <FormLabel>New Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label="Toggle new password visibility"
                                        icon={showNewPassword ? <FiEyeOff /> : <FiEye />}
                                        onClick={() => handleClickShowPassword('new')}
                                        onMouseDown={handleMouseDownPassword}
                                        size="sm"
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="confirm-password" isRequired>
                            <FormLabel>Confirm New Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm new password"
                                />
                                <InputRightElement>
                                    <IconButton
                                        variant="ghost"
                                        aria-label="Toggle confirm password visibility"
                                        icon={showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                                        onClick={() => handleClickShowPassword('confirm')}
                                        onMouseDown={handleMouseDownPassword}
                                        size="sm"
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Button type="submit" colorScheme="blue" width="full">
                            Change Password
                        </Button>
                        {message && (
                            <Text color="red.500" textAlign="center">
                                {message}
                            </Text>
                        )}
                    </VStack>
                </Box>
            </Box>
        </Container>
    )
}

export default ChangePassword
