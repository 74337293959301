// Chakra imports
import {
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
    Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
    const {startContent, endContent, name, growth, value, period, roasTime} = props;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";

    return (
        <Card py='15px'>
            {roasTime && (
                <Text
                    position="absolute"
                    top="15px"
                    right="20px"
                    fontSize="xs"
                    color={textColorSecondary}
                >
                    {roasTime}
                </Text>
            )}
            <Flex
                my='auto'
                h='100%'
                align={{base: "center", xl: "start"}}
                justify={{base: "center", xl: "center"}}>
                {startContent}

                <Stat my='auto' ms={startContent ? "18px" : "0px"}>
                    <StatLabel
                        lineHeight='100%'
                        color={textColorSecondary}
                        fontSize={{
                            base: "sm",
                        }}>
                        {name}
                    </StatLabel>
                    <StatNumber
                        color={textColor}
                        fontSize={{
                            base: "2xl",
                        }}>
                        {value}
                    </StatNumber>
                    {growth ? (
                        <Flex align='center'>
                            <Text
                                color={parseFloat(growth) > 0 ? 'green.500' : 'red.500'}
                                fontSize='xs'
                                fontWeight='700'
                                me='5px'
                            >
                                {parseFloat(growth).toFixed(0)}%
                            </Text>
                            {period && <Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
                                since {period}
                            </Text>}
                        </Flex>
                    ) : <Text
                        color={'secondaryGray.600'}
                        fontSize='xs'
                        fontWeight='700'
                        me='5px'
                    >
                        {period ? `same as ${period}` : null}
                    </Text>
                    }
                </Stat>
                <Flex ms='auto' w='max-content'>
                    {endContent}
                </Flex>
            </Flex>
        </Card>
    );
}
